<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="headline">Campaigns</div>
      <div v-if="!isFullyManaged && isBusiness" class="mb-3">
        <v-flex class="text-right">
          <v-dialog
            v-model="campaignCreateDialog"
            max-width="600px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                data-intercom-target="btn.create_campaign"
                :disabled="disableCreateBtn"
                v-bind="attrs"
                v-on="on"
              >
                Create campaign
              </v-btn>
              <div v-if="disableCreateBtn" class="supporting-text">
                Max 10 drafts
              </div>
              <!--TODO: Insert mobile FAB button-->
            </template>

            <v-card>
              <v-card-title class="headline">
                Create new campaign
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-select
                    v-model="country"
                    outlined
                    name="country"
                    placeholder="Land"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    :loading="!countries.length"
                  ></v-select>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="closeDialog">
                  Cancel
                </v-btn>
                <v-btn color="primary" @click="newCampaign">
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
      </div>
    </div>

    <campaigns-tabs></campaigns-tabs>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CampaignsTabs from "../Tabs/CampaignsTabs/CampaignsTabs";

export default {
  components: { CampaignsTabs },
  data: () => ({
    country: 61,
    countries: [],
    campaignCreateDialog: false,
    drafts: null,
    maxDraftsAllowed: 10
  }),
  computed: {
    ...mapState("core/auth", {
      auth: state => state
    }),
    ...mapGetters("subscriptions", ["getActiveSubscription"]),
    ...mapGetters("core/auth", [
      "isAgency",
      "isBusiness",
      "isInfluencer",
      "isFullyManaged"
    ]),
    businessUuid() {
      return this.auth.user.business.uuid;
    },
    disableCreateBtn() {
      return this.drafts >= this.maxDraftsAllowed;
    }
  },
  methods: {
    ...mapActions("core", ["getCountries"]),
    ...mapActions("core/campaigns", ["loadCampaigns", "createCampaign"]),
    loadActiveCountries() {
      this.getCountries({ active: true }).then(
        countries => {
          this.countries = countries;
        },
        error => {
          console.log(error);
        }
      );
    },
    closeDialog() {
      this.campaignCreateDialog = false;
      this.country = 61;
    },
    newCampaign() {
      this.createCampaign({ business_uuid: this.businessUuid, country_id: this.country }).then(
        createCampaign => {
          this.$router.push({
            name: "campaign.create",
            params: { id: createCampaign.uuid }
          });
        },
        error => {
          console.log(error);
          this.setSnackError("Something went wrong.");
        }
      );
    }
  },
  mounted() {
    this.loadActiveCountries();
  },
  created() {
    if (!this.isInfluencer) {
      let params = { draft: true, page: 1, limit: 10 };
      this.loadCampaigns(params).then(campaigns => {
        this.drafts = campaigns.total;
      });
    }
  }
};
</script>
